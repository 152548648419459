<template>
  <div class="footer pixel">
    <div class="con" v-for="(item, ind) in list" :key="ind">
      <div class="score pixel">{{item.title}}</div>
      <div class="scoreTable">
        <el-table
          :data="item.texts"
          class="table"
          border
          :header-cell-style="{ 'text-align': 'center' }"
        >
          <el-table-column prop="username" label="名称"> </el-table-column>
          <el-table-column prop="text" label="内容"> </el-table-column>
          <el-table-column prop="replyTime" label="回复时间"> </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "footer",
  props: ["list"],
  data() {
    return {};
  },
  mounted() {
    setTimeout(() => {
      // console.log(this.list);
    }, 300);
  },
};
</script>

<style lang="scss" scoped>
.footer {
  background: #fff;
  padding: 10px 15px 15px 15px;
  &.pixel {
    font-size: 10px;
  }
}
.score {
  background: #f5fff7;
  border-left: #008755 2px solid;
  padding: 10px 15px;
}
.table {
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
  ::v-deep .cell {
    display: flex;
    justify-content: center;
  }
}
</style>
