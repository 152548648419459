<template>
  <div class="statistics">
    <div class="circle-content">
      <div class="circle-item">
        <div class="circle-title">{{list.scoreTitle}}</div>
        <div class="progress">
          <el-progress
            :percentage="Number((100 / list.maxScore * list.score).toFixed(2))"
            type="circle"
            class="circle"
            :stroke-width="10"
          />
        </div>
      </div>
      <div class="circle-item">
        <div class="circle-title">回收答卷数</div>
        <div class="progress">
          <el-progress
            :percentage="Number((100 / list.views * list.recycled).toFixed(2))"
            type="circle"
            class="circle"
            :stroke-width="10"
          />
        </div>
      </div>
    </div>
    <svg
      width="100%"
      height="100%"
      class="svg"
    >
      <defs>
        <linearGradient
          id="blue"
          x1="0%"
          y1="0%"
          x2="100%"
          y2="0%"
        >
          <stop
            offset="0%"
            style="stop-color:#BADF34"
            stop-opacity="1"
          ></stop>
          <stop
            offset="100%"
            style="stop-color:#008755"
            stop-opacity="1"
          ></stop>
        </linearGradient>
      </defs>
    </svg>
    <statistics :con-list="list" />
    <Footer :list="table" ></Footer>
  </div>
</template>

<script>
import apis from "@/apis";
import statistics from '@/views/questionnaire/statistics/component/content.vue'
import Footer from '@/views/questionnaire/statistics/component/footer.vue'

export default {
  components: {
    statistics,
    Footer
  },
  data() {
    return {
      list: {},
      table :[]
    }
  },
  methods: {
    getData() {
      apis.questionnaireStatistical({
        id: this.$route.query.id,
      }).then(({ data }) => {
        this.list = data;
        this.table = data.texts;
      });
    }
  },
  mounted() {
    this.getData();
  }
}
</script>

<style lang="scss" scoped>
$zoom: 1020 / 375;

.statistics {
  padding: 8px * $zoom;
}
.circle-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px * $zoom 16px * $zoom;
  background: #ffffff;
  border-radius: 8px * $zoom;

  .circle-title {
    margin-bottom: 20px * $zoom;
    font-size: 14px * $zoom;
    font-weight: 500;
    color: #3d3d3d;
    text-align: center;
  }

  .circle-item {
    flex: 1;
  }

  .progress {
    display: flex;
    justify-content: center;
  }

  .circle ::v-deep svg > path:nth-child(2) {
    background: red;
    stroke: url(#blue);
  }
}
.svg {
  position: fixed;
  top: -3000px;
  left: -3000px;
}
</style>